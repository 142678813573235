.Services-container{
    margin-top: 100px;
}

.Services-ourServices-section{
    display: flex;
    max-width: 1200px;
    margin-inline: auto;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 100px;
}

.Services-ourServices-section-texts{
    text-align: center;
    max-width: 800px;
    margin-inline: auto;
}


.Services-ourServices-section-texts > p{
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    color: #000000;
    line-height: 25px;
    margin: 10px 0;
    font-weight: 500;
}


.Services-ourServices-section-cards {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 10px;
    row-gap: 20px;
    padding: 0px 16px
}

.Services-ourServices-section-card {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    background: #ffffff;
    padding: 8px 16px;
    box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
    background: rgba(255, 255, 255, 0.96);
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid transparent;
}

.Services-ourServices-section-card > p {
    margin: 0px;
    padding: 0px;
    font-size: 17px;
    font-weight: 700;
}

.Services-ourServices-section-card > p:nth-child(2) {
    font-size: 15px;
    font-weight: 500;
    color: #808080;
}

.Services-ourServices-section-card:hover {
    color: var(--primary-text-color);
    border: 1px solid var(--primary-text-color);
}

.Services-whatwedo-section{
    padding: 50px 16px;
    background: var(--bg-color);
}

.Services-whatwedo-section-content{
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Services-whatwedo-section-content-cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 50px 0px;
}

.Services-whatwedo-section-content-card > p{
    font-size: 22px;
    font-weight: 700;
}

.Services-whatwedo-section-content-card > p:nth-child(2){
    font-size: 16px;
    font-weight: 600;
}

.Services-staffStrenth-section{
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 50px 16px;
}


@media screen and (max-width: 780px) {
        
    .Services-ourServices-section-cards {
        grid-template-columns: repeat(2,1fr);
    }
        
    .Services-whatwedo-section-content-cards {
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (max-width: 425px) {
        
    .Services-ourServices-section-cards {
        grid-template-columns: repeat(1,1fr);
    }
        
    .Services-whatwedo-section-content-cards {
        grid-template-columns: repeat(1,1fr);
    }   
}
