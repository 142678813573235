
.About-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 1200px;
    margin-inline: auto;
    padding-top: 100px;
    padding-bottom: 30px;
    padding-inline: 16px;
}

.About-container > p{
    font-size: 18px;
    text-align: center;
    font-family: Montserrat, sans-serif;
    color: #000000;
    line-height: 25px;
    margin: 10px 0;
    font-weight: 500;
}

.About-intro-cards-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 10px;
    row-gap: 20px;
}

.About-intro-cards-section > span {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    background: #ffffff;
    padding: 15px 0px;
    box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
    background: rgba(255, 255, 255, 0.96);
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid transparent;
}

.About-intro-cards-section > span:hover {
    color: var(--primary-text-color);
    border: 1px solid var(--primary-text-color);
}

.staffStrenghtCards {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3,1fr);
    column-gap: 20px;
    row-gap: 30px;
}

.staffStrenghtCard{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-inline: 20px;
    cursor: pointer;
    background: var(--bg-color);
    border-radius: 20px;
    transition: all ease-in-out 0.2s;
}

.staffStrenghtCard:hover{
    box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
}

.staffStrenghtCard > span{
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--primary-text-color);
    border: 1px solid var(--primary-text-color);
    /* padding: 2px 15px; */
    height: 40px;
    border-radius: 10px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.staffStrenghtCard > p{
    font-size: 1.3rem;
    font-weight: 600;
}

.About-counters {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 25px;
    row-gap: 75px;
    padding-bottom: 30px;
}

.About-counter-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.About-counter-card > p{
    font-size: 23px;
    font-weight: 600;
    margin: 0px;
    text-align: center;
}

.About-counter-card > p:first-child{
    font-size: 36px;
    font-weight: 600;
}

.About-counter-card > span:nth-child(2){
    height: 3px;
    background: var(--primary-text-color);
    width: 50px;
}

@media screen and (max-width: 780px) {
        
    .staffStrenghtCards {
        grid-template-columns: repeat(2,1fr);
    }
        
    .About-counters {
        grid-template-columns: repeat(2,1fr);
    }
        
    .About-container > p{
        font-size: 16px;
    }
}

@media screen and (max-width: 650px) {

    .About-intro-cards-section {
        grid-template-columns: repeat(2,1fr);
    }    
    
    .staffStrenghtCards {
        grid-template-columns: repeat(1,1fr);
    }
}

@media screen and (max-width: 425px) {

    .About-intro-cards-section {
        grid-template-columns: repeat(1,1fr);
    }    

    .About-counters {
        grid-template-columns: repeat(1,1fr);
    }
}

@media screen and (max-width: 320px) {

    .staffStrenghtCards {
        column-gap: 10px;
        row-gap: 10px;
    }
    
    .staffStrenghtCard{
        gap: 10px;
    }
    
    .staffStrenghtCard > span{
        font-size: 1rem;
        padding: 2px 8px;
    }
    
    .staffStrenghtCard > p{
        font-size: 1rem;
    }
}