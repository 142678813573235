

.Home-hero-section{
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    background: black;
}

.Home-hero-section > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}

.Home-hero-texts {
    max-width: 1200px;
    position: absolute;
    margin-inline: auto;
    left: 0px;
    right: 0px;
    text-align: center;
}


.Home-hero-texts > h1 {
    font-size: 4rem;
    color: var(--secondary-text-color);
    margin: 0px;
}

.Home-hero-texts > h2{
    font-size: 3rem;
    color: #ffffff;
    margin: 0px;
}

/* Introduction */

.Home-intro-section {
    padding-block: 30px;
    background: var(--bg-color);
}

.Home-intro-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 1200px;
    margin-inline: auto;
}

.Home-intro-content > p{
    font-size: 18px;
    text-align: center;
    font-family: Montserrat, sans-serif;
    color: #000000;
    line-height: 25px;
    margin: 10px 0;
    font-weight: 500;
}

.Home-intro-cards-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 10px;
    row-gap: 20px;
}

.Home-intro-cards-section > span {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    background: #ffffff;
    padding: 15px 0px;
    box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
    background: rgba(255, 255, 255, 0.96);
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid transparent;
}

.Home-intro-cards-section > span:hover {
    color: var(--primary-text-color);
    border: 1px solid var(--primary-text-color);
}

/* Clients section */

.Home-clients-section {
    position: relative;
    background: #ffffff;
    padding-top: 35px;
}

@media screen and (max-width: 768px){

    .Home-intro-content > p{
        font-size: 16px;
    }
}

@media screen and (max-width: 520px) {


    .Home-hero-texts > h1 {
        font-size: 2rem;
        color: var(--secondary-text-color);
        margin: 0px;
    }
    
    .Home-hero-texts > h2{
        font-size: 1.5rem;
        color: #ffffff;
        margin: 0px;
    }   
}