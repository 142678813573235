.Header-container{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    padding-inline: 4px;
}

.Header-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin-inline: auto;
    gap: 30px;
    box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
    background: rgba(255, 255, 255, 0.96);
    border-radius: 15px;
    padding: 10px;
    position: relative;
}

.Header-content > img {
    width: 60px;
    padding: 8px
}

.Header-options {
    display: flex;
    align-items: stretch;
    gap: 35px;
}

.Header-options > a{
    text-decoration: none;
    font-size: 1.2rem;
    color: black;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 3px solid transparent;
}

.Header-options > a:hover{
    color: var(--primary-text-color);
    border-bottom: 3px solid var(--primary-text-color);
}

.Header-option-active-class{
    color: var(--primary-text-color) !important;
    border-bottom: 3px solid var(--primary-text-color) !important;
}

.menuIcon{
    display: none;
}

.Header-responsive-options{
    display: flex;
    position: absolute;
    flex-direction: column;
    right: 30px;
    top: 80px;
    background: white;
    padding: 10px 20px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(135,135,135,1);
    -moz-box-shadow: 0px 2px 8px 0px rgba(135,135,135,1);
    box-shadow: 0px 2px 8px 0px rgba(135,135,135,1);
}

.Header-responsive-options > a{
    text-decoration: none;
    font-size: 16px;
    color: black;
    font-weight: 500;
    padding: 6px 0px;
    border-bottom: 3px solid transparent;
}

.Header-responsive-options > a:hover{
    color: var(--primary-text-color);
    border-bottom: 2px solid var(--primary-text-color);
}

@media screen and (max-width: 768px) {
        
    .Header-options {
        display: none;
    }

    .Header-content {
        justify-content: space-between;
    }

    .menuIcon{
        display: block;
        width: 50px;
        font-size: 25px;
    }
}

@media screen and (max-width: 475px){
    .Header-content{
        height: 50px;
        gap: 10px;
        padding: 10px 0px;
    }

    .Header-options {
        gap: 15px;
    }

    .Header-options > a{
        font-size: 0.8rem;
    }

    .Header-content > img {
        width: 50px;
    }
}
