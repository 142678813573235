body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary-text-color: rgb(45, 102, 183);
  --secondary-text-color: rgb(255, 255, 255);
  --bg-color: rgb(253, 253, 253);
}

.headingBorderBottom{
  text-align: center;
  font-size: 1.6rem;
  display: inline-block;
  margin: 0px;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--primary-text-color);
  color: var(--primary-text-color);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}