.Contact-container{
    max-width: 1200px;
    margin-inline: auto;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-inline: 30px;
}

.contact-details-section{
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.contact-details-section > div{
    width: 45%;
}

.contact-details-section-left{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-details-section-left > div{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contact-details-section-left > div > h5{
    margin: 0px;
    font-size: 20px;
}
.contact-details-section-left > div > p{
    margin: 0px;
    font-weight: 600;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
        
    .contact-details-section{
        flex-direction: column;
    }

    .contact-details-section > div{
        width: 100%;
    }
}