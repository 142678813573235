
.embla1 {
    --slide-spacing: 1rem;
    --slide-size: 50%;
    --slide-height: 19rem;
  }
  .embla__viewport1 {
    overflow: hidden;
  }
  .embla__container1 {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide1 {
    padding-left: 85px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .embla__slide__img1 {
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
  }
  .embla__slide__number1 {
    width: 4.6rem;
    height: 4.6rem;
    z-index: 1;
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    border-radius: 50%;
    background-color: rgba(var(--background-site-rgb-value), 0.85);
    line-height: 4.6rem;
    font-weight: 500;
    text-align: center;
    pointer-events: none;
  }
  .embla__slide__number1 > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .client-logo {
      width: 8rem;
  }