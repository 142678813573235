.Footer-container{
    background: #040E27;
    padding: 30px 10px;
    padding-bottom: 0px;
}

.Footer-content{
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.Footer-content > h3{
    color: #ffffff;
    font-size: 2rem;
    font-weight: 400;
    margin: 0px;
    text-align: center;
    margin-bottom: 30px;
}

.Footer-contact-options{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    margin: 60px 0px;
    margin-top: 10px;
}

.Footer-contact-option{
    width: 20%;
}
/* 
.Footer-contact-option:last-child{
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
} */

.Footer-contact-option > p {
    color: #ffffff;
    margin: 0px;
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}

.Footer-contact-texts {
    color: #919191;
}

.Footer-contact-texts > p{
    margin: 0px;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 0px;
}

.Footer-contact-input{
    width: 100%;
    height: 50px;
    max-height: 50px;
    background: #F5FBFF;
    font-size: 15px;
    color: #000;
    border: 1px solid #E8E8E8;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-right: 5px;
}

.Footer-contact-input > input {
    border: none;
    outline: none;
    flex: 1;
    background: #F5FBFF;
    height: 100%;
    display: inline-block;
    height: 95%;
    font-size: 1.1rem;
    margin-left: 20px;
    width: inherit;
}

.Footer-contact-input > button {
    border-radius: 30px;
    height: 95%;
    border: none;
    width: 150px;
    min-width: 140px;
    cursor: pointer;
    background: var(--primary-text-color);
    font-size: 1.1rem;
    color: #F5FBFF;
    cursor: pointer;
}

.Footer-copyright-section {
    border-top: 2px solid #919191;
    color: #919191;
    text-align: center;
    padding: 10px 8px;
}

@media screen and (max-width: 980px) {
        
    .Footer-contact-options{
        flex-direction: column;
    }

    .Footer-contact-option{
        width: 100%;
    }

    .Footer-contact-input{
        width: 70%;
    }
}

@media screen and (max-width: 440px) {

    .Footer-contact-input{
        width: 95%;
    }
}